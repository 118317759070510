import { Card, Typography } from "@material-tailwind/react";
import { TABLE_HEAD, TABLE_ROWS } from './repertuarData';

const Repertuar = () => {
    return (
        <div className="mx-auto px-4 sm:px-8 lg:px-16 xl:px-40 mt-4">
            <h1 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-center text-white">Repertuar</h1>
            <br />
            <Card className="h-full w-full overflow-auto">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70 text-center"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {TABLE_ROWS.map(({ name, miesiac, projekt, spetakl, date, link, tekst }, index) => {
                            const isLast = index === TABLE_ROWS.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                                <tr key={name}>
                                    <td className="border-b border-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="black"
                                            className="font-normal"
                                        >
                                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center font-semibold">{name}</p>
                                            <p className="text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-center">{miesiac}</p>
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            <p className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl text-center">{spetakl}</p>
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {projekt && (
                                                <p className="text-xl text-red-500 text-center">{projekt}</p>
                                            )}
                                            <p className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl text-center">{date}</p>
                                        </Typography>
                                    </td>
                                    <td className="text-center border-b border-blue-gray-50 p-4">
                                        {link ? (
                                            <a href={link} target="_blank" rel="noopener noreferrer">
                                                <button className="font-medium bg-red-500 text-white py-2 px-4 rounded">
                                                    ZAREZERWUJ
                                                </button>
                                            </a>
                                        ) : (
                                            // Zmniejszona czcionka dla tekstu
                                            <p className="text-xs sm:text-sm md:text-md lg:text-lg text-center text-gray-500">{tekst}</p>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Card>
        </div>
    );
};

export default Repertuar;
