import React, { useState, useEffect }from "react";
import { useParams } from "react-router-dom";
import aktualnosciData from "./aktualnosciData";
import DescriptionWithImage from "./DescriptionWithImage";
import dofinasofanie from "./zdjecia/dofinasowanie/NCK.jpg"
import stopka from "./zdjecia/dofinasowanie/Belkaloga.jpg"
import przed from "./zdjecia/dofinasowanie/flagaigodło.png"

const Rozszerzenie = () => {
  const { id } = useParams(); // Pobierz id z parametrów URL
  const selectedNews = aktualnosciData.find(news => news.id === parseInt(id)); // Znajdź news po id

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  
  if (!selectedNews) {
    return <div>Nie znaleziono takiego newsa.</div>;
  }

  // Pobierz obrazy z selectedNews
  const carouselImages = selectedNews.carouselImages;

  return (
    <div className="p-6 mt-20">
      <div className="w-full md:w-3/5 lg:w-1/2 mx-auto mb-4 rounded-xl mt-8 md:mt-0">
        <img src={carouselImages[0].src} alt={carouselImages[0].alt} className="object-cover h-48 w-full md:h-96 md:w-auto mx-auto mb-4 rounded-xl mt-8 md:mt-0" />
      </div>
      <h1 className="text-4xl font-semibold text-neutral-600 mb-2 text-white text-center">
        {selectedNews.title}
      </h1>
      <p
        className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"
        dangerouslySetInnerHTML={{ __html: selectedNews.content }}
      />

      {selectedNews.id === 1 && (
        
        <div className="text-center p-8">
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Pieśni Rzeki to działanie artystyczne skierowane do mieszkańców i gości miejscowości położonych
        wzdłuż podlaskiej rzeki Supraśl z Gmin: Michałowo, Supraśl, Gródek, Wasilków. Punktem wyjścia do
        działań jest spektakl Teatru Łątek Supraśl pn. „Pieśń rzeki". Powstał on na bazie zebranych polskich
        ballad ludowych, w których głównym wątkiem jest woda, ciecz, rzeka. W każdej z czterech miejscowości
        odbędą się warsztaty etnograficzne poświęcone tradycji, obrzędom i zwyczajom związanymi z wodą oraz
        warsztaty śpiewu tradycyjnego. Zwieńczeniem tych działań będzie plenerowy spektakl „Pieśni rzeki"
        wraz z muzyką na żywo, którą współtworzyć będą uczestnicy warsztatów śpiewaczych. Chcemy
        przypomnieć jak ważną rolę w lokalnej kulturze, tradycji i życiu małych społeczeństw pełniła niegdyś
        rzeka. Udział w każdym z wydarzeń jest bezpłatny.</p>
        <br></br>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>Warsztaty śpiewu tradycyjnego </strong>prowadzone przez Anię Brodę oraz Julitę Charytoniuk</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>Warsztaty wyplatania wianków połączone z gawędą etnograficzną</strong> prowadzone przez Emilię Szatyłowicz (Ruda na Podlasiu)</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>Pieśni Rzeki – spektakl plenerowy</strong> Teatru Łątek (Ewa i Krzysztof Zemło)</p>
        <br></br>
        <p className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl text-gray-400"><strong>Warsztaty śpiewu tradycyjnego</strong></p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>26 lipca 2024. godz. 16:00</strong> Supraśl, Centrum Kultury i Rekreacji w Supraślu, Cieliczańska 1 – prowadzenie Anna Broda</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>27 lipca 2024 godz. 16:00</strong> Wasilków – Miejski Ośrodek Animacji Kultury w Wasilkowie, Żurawia 13 – prowadzenie Anna Broda</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>16 sierpnia 2024, godz. 16:00</strong> Michałowo, Gminny Ośrodek Kultury w Michałowie, Białostocka 19 – prowadzenie Julita Charytoniuk</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>17 sierpnia 2024, godz. 16:00</strong> Gródek, Gminne Centrum Kultury w Gródku, Chodkiewiczów 4 - prowadzenie Julita Charytoniuk</p>
        <br></br>
        <p className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl text-gray-400"><strong>Pieśni Rzeki – spektakl z muzyką na żywo i warsztaty etnograficzne</strong></p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>27 lipca 2024, Supraśl</strong> (zakręt rzeki przy klasztorze)</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">18:30 Warsztaty wyplatania wianków - prowadzenie Emilia Szatyłowicz</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">21:00 Pieśni Rzeki - spektakl plenerowy (Krzysztof Zemło, Ewa Zemło)</p>
        <br></br>

        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>28 lipca 2024, Wasilków</strong> (Cypel nad rzeką Supraśl)</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">18:30 Warsztaty wyplatania wianków - prowadzenie Emilia Szatyłowicz</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">21:00 Pieśni Rzeki - spektakl plenerowy (Krzysztof Zemło, Ewa Zemło)</p>
        <br></br>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>17 sierpnia 2024, Michałowo</strong> (Zejście do rzeki przy amfiteatrze)</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">18:30 Warsztaty wyplatania wianków - prowadzenie Emilia Szatyłowicz</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">21:00 Pieśni Rzeki - spektakl plenerowy (Krzysztof Zemło, Ewa Zemło)</p>
        <br></br>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>18 sierpnia 2024, Gródek</strong> (nad Rzeką za Centrum Kultury)</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">18:30 Warsztaty wyplatania wianków - prowadzenie Emilia Szatyłowicz</p>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">21:00 Pieśni Rzeki - spektakl plenerowy (Krzysztof Zemło, Ewa Zemło)</p>
        <br></br>
        <h2 className="font-bold text-2xl sm:text-3xl md:text-3xl lg:text-3xl text-gray-400">Warsztaty śpiewu tradycyjnego  </h2>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Zapraszamy osoby śpiewające, ale też te które nie mają doświadczenia. Podczas zajęć uczestnicy poznają swój głos i jego możliwości. W trakcie warsztatów pojawią się ćwiczenia ruchowe,
oddechowe, rozluźniające struny głosowe, dykcyjne. Punktem wyjścia będą pieśni tradycyjne
wykorzystane sztuce „Pieśni rzeki”. Osoby chętne będą mogły wziąć udział jako wykonawcy partii
śpiewanych podczas spektaklu „Pieśni Rzeki”. Prowadzenie Ania Broda/Julita Charytoniuk</p>
<br></br>
        <h2 className="font-bold text-2xl sm:text-3xl md:text-3xl lg:text-3xl text-gray-400">Warsztaty wyplatania wianków i pogadanką etnograficzną.  </h2>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Zapraszamy wszystkich chętnych do udziału w warsztatach tworzenia wianków, które odbędą się przed wieczornym spektaklem. Podczas warsztatów poza wyplataniem wianków uczestnicy posłuchają opowieści o roli ziół i roślin w kulturze i tradycji, o zwyczajach związanych i tradycjach związanych z wodą. Prowadzenie Emilia Szatyłowicz</p>
        <br></br>
        <h2 className="font-bold text-2xl sm:text-3xl md:text-3xl lg:text-3xl text-gray-400">Pieśni Rzeki – spektakl plenerowy z muzyką na żywo Anny Brody</h2>
        <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"> „Pieśni rzeki” to spektakl, do którego inspiracją były polskie ballady ludowe. Na ich bazie stworzono
teatrodyski - inscenizacje pieśni realizowane w różnych technikach teatru cieni i światła. Układają się one
w opowieść - teatralną balladę o miłości i śmierci . Ważnym był dobór utworów. Elementem wspólnym
dla wszystkich był motyw rzeki, wody, cieczy. Miłość jest bowiem nurtem, który nas porywa, czy tego
chcemy czy nie, i nie wiadomo gdzie nas zaniesie. Podczas spektaklu aktorzy-animatorzy nie
wypowiadają ani jednego słowa. Przemawiają obrazy, muzyka i śpiew, które stanowią warstwę
dźwiękową całego spektaklu. W trakcie spektaklu muzyka będzie grana na żywo (cymbały Anna Broda i
śpiew Anna Broda/Julita Charytoniuk i uczestnicy warsztatów śpiewu).</p>
<br></br>


          <h2 className="font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white">
              Pieśni rzeki – rzeka tradycji (Zespół realizacyjny)
          </h2>
          <br></br>
          <p className=" text-white text-center text-2xl ">Krzysztof Zemło – reżyseria, aktor-animator</p>
          <p className=" text-white text-center text-2xl ">Ewa Zemło – scenografia, grafika, aktorka-animatorka</p>
          <p className=" text-white text-center text-2xl ">Ania Broda – muzyka, śpiew, warsztaty pieśni tradycyjnych</p>
          <p className=" text-white text-center text-2xl ">Julita Charytoniuk - śpiew, warsztaty pieśni tradycyjnych</p>
          <p className=" text-white text-center text-2xl ">Emilia Szatyłowicz – warsztaty wyplatania/etnograficzne</p>
          <p className=" text-white text-center text-2xl ">Przemysław Waczyński - koordynacja</p>


          <DescriptionWithImage
            imageSrc={carouselImages[4].src}
            altText={carouselImages[4].alt}
            title="Krzysztof Zemło"
            description="Aktor, animator, menadżer kultury, absolwent reżyserii na Akademii Teatralnej Wydziału Sztuki Lalkarskiej w Białymstoku, założyciel i prezes Stowarzyszenia Teatr Okno. Pracował jako aktor w Teatrze Lalki i Aktora w Łomży oraz współpracował z Teatrem Dramatycznym w Białymstoku. Obecnie kieruje Teatrem Łątek Supraśl oraz Miejskim Domem Kultury – Domem Środowisk Twórczych w Łomży. Reżyserował sztuki w teatrach instytucjonalnych i offowych. Organizował i prowadził liczne warsztaty teatralne adresowane do dzieci, młodzieży, dorosłych oraz osób ze środowisk zagrożonych wykluczeniem. Dwukrotny Stypendysta Marszałka Województwa Podlaskiego, Stypendysta Ministra Kultury odznaczony medalem Zasłużony Kulturze Polskiej. Założyciel i współwłaściciel Teatru Łątek Supraśl – najmniejszego teatru w Polsce."
          />

          <DescriptionWithImage
            imageSrc={carouselImages[7].src}
            altText={carouselImages[7].alt}
            title="Ewa Zemło"
            description="Absolwentka Liceum Plastycznego w Supraślu i Wydziału Architektury Politechniki Białostockiej. Autorka scenografii realizowanych w teatrach w Polsce i za granicą, m.in. Teatrze Dramatycznym w Białymstoku, Teatr Pleciuga w Szczecinie, Divadlo Loutek w Ostravie i Francji. Od lat współtworzy Teatr Łątek Supraśl oraz Teatr Okno kreując warstwę plastyczną spektakli i działań teatralnych. Realizator wielu warsztatów artystycznych i scenograficznych. Uprawia głównie olejne malarstwo sztalugowe. Swoje obrazy i instalacje prezentowała na wystawach zbiorowych i indywidualnych. Stypendystka Marszałka Województwa Podlaskiego. Założycielka i współwłaścicielka Teatru Łątek Supraśl – najmniejszego teatru w Polsce."
            reverse
          />

          <DescriptionWithImage
            imageSrc={carouselImages[5].src}
            altText={carouselImages[5].alt}
            title="Ania Broda"
            description="Anna Kowalska - pseudonim artystyczny Ania Broda to jeden z najoryginalniejszych głosów na polskiej scenie muzycznej. Piosenkarka, cymbalistka, kompozytorka, autorka tekstów, producentka. Posługuje się techniką śpiewu „białego”. Tworzy bardzo różnorodną w formie, nieprzewidywalną w stylu, absolutnie unikalną muzykę marzeń i wrażeń. Bardzo dobrze bawi się głębokim brzmieniem swojego głosu, siłą słowa, magią nieoczywistej melodii. Jest sama w sobie osobnym gatunkiem muzycznym. Przekracza granice wyznaczone przez kierunki w muzyce takie jak etno, jazz, folk, pop, muzyka dla dzieci. Łączy style poszukując własnego wyrazu w sztuce. Kształtuje duchową stronę tradycyjnej muzyki polskiej. Mistrzyni gry na cymbałach wileńskich, uderzanym instrumencie strunowym, który przywędrował do nas z Azji. Bierze udział w projektach związanych z muzyką, dawną, współczesną, eksperymentalną. Jako lokalna patriotka promuje tradycyjną muzykę z Warmii i Mazur."
          />

          <DescriptionWithImage
            imageSrc={carouselImages[3].src}
            altText={carouselImages[3].alt}
            title="Julita Charytoniuk"
            description="Animatorka kultury, śpiewaczka, rękodzielniczka. Uwielbia śpiewać, a jej ścieżki są ściśle związane są z muzyką tradycyjną Podlasia. Popularyzuje ją od niemal 20 lat – prowadzi badania terenowe, dokumentuje, uczy śpiewu, koncertuję oraz prowadzę działalność wydawniczą i animacyjną. Uczy się od wiejskich śpiewaczek i śpiewaków z Podlasia, Suwalszczyzny i Polesia Zachodniego (Ukraina, Białoruś). Łączy teorię z praktyką jako etnomuzykolożka i kulturoznawczyni. Swoją wiedzą i doświadczeniem dzielę się podczas warsztatów śpiewu tradycyjnego, na które często zaprasza do współpracy wiejskie mistrzynie. Śpiewa w zespołach Z Lasu, Południce, Pieśni Piękne, Biele, Polesie_PL. W białostockiej Kapeli Batareja odpowiadam za sekcję rytmiczną i śpiew. Koordynatorka wielu projektów kulturalnych związanych z muzyką, rękodziełem i językiem regionalnym. Realizuje swoje pomysły również w ramach stypendiów edukacyjnych i artystycznych. Jest również wiceprezeską Stowarzyszenia Dziedzictwo Podlasia oraz redaktorką portalu muzykatradycyjna.pl."
            reverse
          />
          <DescriptionWithImage
            imageSrc={carouselImages[6].src}
            altText={carouselImages[6].alt}
            title="Emilia Szatyłowicz"
            description=" Miłośniczka podlaskiego folkloru pochodząca z Czeremchy, przez niektórych kojarzona jako Ruda Na Podlasiu. Na swoim profilu pokazuje wielokulturowość regionu, podlaskie tradycje i zwyczaje, kuchnię, język, muzykę oraz ciekawe miejsca warte zobaczenia. Dużo miejsca w Jej działalności zajmuje różnego rodzaju rękodzieło, które sama tworzy jak i podziwia u innych lokalnych artystów. Inspiracje czerpie z rzeczy znalezionych w babcinych kufrach, wzorów zachowanych w podlaskich muzeach oraz naturalnego otoczenia."
            
          />
          <DescriptionWithImage
            imageSrc={carouselImages[8].src}
            altText={carouselImages[8].alt}
            title="Przemek Waczyński"
            description="Animator i menadżer kultury, bloger, trener. Już niemal 15 lat pracuje zawodowo w sektorze kultury. Swoją wiedzę i doświadczenie zdobywał uczestnicząc w dziesiątkach szkoleń, spotkań i wizyt studyjnych w najlepszych ośrodkach kultury w Polsce. Często się nią dzieli z innymi animatorami oraz menadżerami kultury. Stypendysta Ministra Kultury, w ramach którego realizował projekt „Podlaska Sieć Kultury”, trwający do dziś. Animator Programu Dom Kultury Plus Narodowego Centrum Kultury oraz propagator idei partycypacyjnego modelu instytucji kultury oraz sieciowania kadr kultury. Koordynator wielu projektów kulturalnych. Aktualnie pracuje w Miejskim Ośrodku Animacji Kultury w Wasilkowie, stale współpracuje z Teatrami Łątek Supraśl oraz Latarnia. Prowadzi blog pwkulturalnie.pl, na którym dzieli się swoimi refleksjami na temat kadr oraz instytucji kultury."
            reverse
          />
           <div className="flex justify-center mt-4">
            <img
              src={dofinasofanie}
              alt=" Narodowe Centrum Kultury"
              className="w-32 h-auto max-w-full object-contain sm:w-40 md:w-48 lg:w-60 xl:w-80"
              />
          </div>
        </div>
      )}
      {selectedNews.id === 3 && (
        <div className="text-center">
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Zapraszamy do wzięcia udziału w 4 edycji naszego kameralnego festiwalu. Teatr w Ogrodzie to multidyscyplinarny festiwal sztuk ze szczególnym naciskiem na działania teatralne.</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Cechą charakterystyczną wydarzenia, bardzo ważną dla jego unikalnego klimatu i charakteru, jest miejsce jego realizacji. Widzów gościmy u siebie - na jednym z podwórek należących do supraskich artystów. Tu rozstawiamy mobilną scenę teatralną, wiatę przerabiamy na galerię, taras na scenę muzyczną. Tak przygotowana przestrzeń pozwala uczestnikom spotkać się ze sztuką i ze sobą nawzajem. W tym roku, w kolejnych dwóch sierpniowych sobotach, widzowie będą mieli możliwość wzięcia udziału w wernisażu wystawy plastycznej, koncercie i dwóch spektaklach teatralnych.</p>
          <br></br>
          <h2 className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>PROGRAM</strong></h2>
          <br></br>
          <h3 className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400"><strong>3 sierpnia</strong></h3>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">19.00 JAJO - wernisaż wystawy Ewy Zemło</p>
          <br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">19.30 „Niebieskie wspomnienia” -koncert Julii Kuzyki</p>
          <br></br>
          <br></br>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">19.00 „Cudowni Mężczyźni z korbką” – spektakl Teatru A. Walnego</p>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">20.00 „Matecznik” – spektakl Teatru Łątek Supraśl</p>
          <br></br>
          <br></br>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Miejsce realizacji zadania Ogród przy ulicy Posterunkowej 1 A w Supraślu.</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Uczestnictwo we wszystkich działaniach jest bezpłatne.</p>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Organizator: Stowarzyszenie Teatr Okno</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Partner: Fundacja Teatr Łątek</p>
        </div>
      )}
      {selectedNews.id === 4 && (
        <div className="text-center">
          <br></br>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={przed} alt="Logo" style={{ width: "auto", maxWidth: "200px", height: "auto" }} />
          </div>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">„Nosferatu – dziennik zarazy” to nowy spektakl wykonany w technice kina manualnego, który
przeznaczony jest do wystawiania w mobilnej scenie. Sztukę mogli zobaczyć widzowie w Supraślu,
Krypnie, Czeremsze, Goniądzu i Szudziałowie. Spektakl zrealizowano w ramach Programu OFF Polska. Premiera odbyła się 29 września 2024 roku w Supraślu. Dofinansowanie w kwocie 50 tysięcy złotych
pochodzi z programu OFF Polska 2024, którego cała wartość wynosi 1500 000.</p>
          <br></br>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Spektakl „Nosferatu – dziennik zarazy” jest inspirowany powieścią „Dracula” Brama Stokera oraz filmem „Nosferatu - symfonia grozy” w reżyserii Friedrich Wilhelm Murnau . Te zrealizowane już grubo ponad sto lat temu dzieła opowiadające historię wyprawy Jonathana Harkera do dalekiej Transylwanii i odwiedzin Hrabiego Drakuli w Londynie – stolicy ówczesnego świata, zrobiły wręcz zwrotną karierę, szczególnie w kulturze popularnej. Powodów takiego stanu rzeczy jest wiele, dominującym jednak jest podejmowanie w tych utworach archetypicznego tematu – walki życia ze śmiercią – rywalizacji Erosa i Tanathosa. W spektaklu za pomocą monochromatycznych form cieniowych, zaczerpniętych wprost z filmu Murnaua, w technice kina manualnego – przy pomocy recznie przesuwanej rolki folii -„taśmy filmowej”, spokojnie i z dystansem opowiadamy tę historie po swojemu. Ożywiamy postacie i fabułę , żeby zastanowić się, czy my, mieszkańcy współczesnego Wisborga jesteśmy żywi, czy po prostu jeszcze jesteśmy nieumarli…?</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Spektaklowi – kina manualnego towarzyszyć będzie wystawa, na której widzowie będą mogli
zobaczyć rożne wyobrażenia najbardziej znanego z wampirów – Draculi, które powstały podczas
ponad stuletniej historii kina.</p>
          <br></br>
          <br></br>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Spektaklowi – kina manualnego towarzyszyć będzie wystawa, na której widzowie będą mogli
zobaczyć rożne wyobrażenia najbardziej znanego z wampirów – Draculi, które powstały podczas
ponad stuletniej historii kina.</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">20.00 „Matecznik” – spektakl Teatru Łątek Supraśl</p>
          <br></br>
          <br></br>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Miejsce realizacji zadania Ogród przy ulicy Posterunkowej 1 A w Supraślu.</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Uczestnictwo we wszystkich działaniach jest bezpłatne.</p>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Obsada – Ewa Agnieszka Zemłol, Krzysztof Zemło</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Reżyseria – Krzysztof Zemło</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Scenografia – Ewa Zemło</p>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Muzyka – Marcin Mickiewicz</p>

          <br></br>
          <br></br>
          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">OFF Polska 2024 Instytutu Teatralnego im. Zbigniewa Raszewskiego. Spektakle będzie można
zobaczyć w 5 miejscowościach Województwa Podlaskiego.</p>
<br></br>
<br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">29 września 2024 Supraśl (Posterunkowa 1) Premiera godz. 17:00, II granie godz. 19:00
Rezerwacja bezpłatnych wejściówek: godz. 17:00 rezerwacja , godz. 19:00 rezerwacja</p>
<br></br>
<br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">5 października.2024 Czeremcha - plac przy Gminnym Ośrodku Kultury, ul. 1 Maja 77, godz. 18:00.
Rezerwacja bezpłatnych wejściówek: rezerwacja</p>
<br></br>
<br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">11 października 2024 Krypno, plac przy Gminnym Ośrodku Kultury, Krypno Wielkie 9, godz.
18:00. Rezerwacja bezpłatnych wejściówek: rezerwacja</p>

<br></br>
<br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">12 października 2024 Goniądz - plac przy Gminny Ośrodku Kultury w Goniądzu, ul. Stary Rynek
23, godz. 18:00. Rezerwacja bezpłatnych wejściówek: rezerwacja</p>
<br></br>
<br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">13 października 2024 Szudziałowo - plac przy Gminnym Ośrodku Animacji Kultury i Rekreacji, ul.
Szkolna 2, godz. 18:00. Rezerwacja bezpłatnych wejściówek: rezerwacja</p>
<br></br>
<br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Wydarzenie odbywa się dzięki wsparciu lukalnych ośrodków kultury: Gminnym Ośrodku Kultury
w Czeremsze, Gminnym Ośrodku Kultury w Krypnie, Gminnym Ośrodku Kultury w Goniądzu
oraz Gminnym Ośrodku Animacji Kultury i Rekreacji w Szudziałowie</p>
<br></br>
<br></br>
<br></br>

          <p className="text-2xl px-4 md:px-20 leading-relaxed text-gray-400">Spektakl powstał w ramach programu OFF Polska organizowanego przez Instytut Teatralny im.
Zbigniewa Raszewskiego . Dofinansowano ze środków Ministra Kultury i Dziedzictwa Narodowego.</p>
<br></br>
<br></br>
<img src={stopka} alt="Logo" className="responsive-img" />
<br></br>



        </div>
      )}
    </div>
  );
};

export default Rozszerzenie;
